import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/preview-react/side-panel/SidePanel.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const LegacyPatternLink = makeShortcode("LegacyPatternLink");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/preview-react/side-panel" storybook="https://workday.github.io/canvas-kit/?path=/story/preview-side-panel--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=19740-8551&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-preview-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/82d8a8e4630f43b4451df5aea05edc5a/536c7/component-anatomy-side-panel.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "63.78378378378379%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAABFUlEQVR42uWSTU/DMAyG9///BxckxMdhSBz4CZzhMtCqrrRrydo0X+2SFztZC9oBadsRS2/l2vVTO84CZCEEeO9xbP4QP9bvfKoN0Wdb8MO6Abuuj4GbhyWurm8RagnxlqHuJBrRodkliVaCaxnUawNrqZZivdIJyAlFiUa0EFT8+PSM+7slQi4wvnyA+4mdhTArARN08vd7H98jkOlFVaOsv+CGEXB7+KqFf92k2Q7j/GX80wikryEJuN6UyPJP5OUWSkhg28GvqnOAgOwV3rMCq3URR3fjiFNtBv6cYRdhfMDWuWmN5wGNsbQtFxV92vpFHWqCTFL6fwB1ArF6ZWHsBUC+h7wIrfUsYwwcbfoUDUNq4hvbCvy5XQ0drQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Side Panel in its expanded state with a Collapse Icon Button.",
              "title": "Image of a Side Panel in its expanded state with a Collapse Icon Button.",
              "src": "/static/82d8a8e4630f43b4451df5aea05edc5a/50383/component-anatomy-side-panel.png",
              "srcSet": ["/static/82d8a8e4630f43b4451df5aea05edc5a/1efb2/component-anatomy-side-panel.png 370w", "/static/82d8a8e4630f43b4451df5aea05edc5a/50383/component-anatomy-side-panel.png 740w", "/static/82d8a8e4630f43b4451df5aea05edc5a/536c7/component-anatomy-side-panel.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Tooltip (Required if using Expand/Collapse Button):`}</strong>{` `}<a parentName="li" {...{
            "href": "/components/popups/tooltip"
          }}>{`Tooltip`}</a>{`
used to provide additional visual affordance for the Expand/Collapse Button.`}</li>
        <li parentName="ol"><strong parentName="li">{`Expand/Collapse Button (Optional):`}</strong>
          <a parentName="li" {...{
            "href": "/components/buttons/button"
          }}>{`Icon only Tertiary Button variant`}</a>{` used to open or close the Side
Panel.`}</li>
        <li parentName="ol"><strong parentName="li">{`Container:`}</strong>{` Rectangular container that houses the contents of the Side Panel. The container
spans the full height of the viewport and are flush to the left or right edge of the screen.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`Side Panels can either push and resize content as it expands within a page or float over page
content. See the Expandable pattern (coming soon!) for more detailed information on horizontal
animation.`}</li>
        <li parentName="ul">{`When the content of the Side Panel exceeds the height of the viewport, overflow behavior such as a
scrollbar is introduced. Refer to the`}
          <LegacyPatternLink href="/patterns/overflow/" mdxType="LegacyPatternLink">Overflow</LegacyPatternLink> pattern for more
information.
        </li>
        <li parentName="ul">{`Consider the behavior of Side Panels at different responsive breakpoints and in different use
cases. In use cases where the Side Panel is used to edit content within the page, keeping the Side
Panel open and resizing the page content may be ideal. For use cases where a Side Panel is not
required to remain open, enabling a Side Panel to automatically collapse when it reaches smaller
screen sizes will prevent the panel from taking up too much of the screen until the user wants to
take action on it.`}</li>
        <li parentName="ul">{`When using the Expand/Collapse Button within the Side Panel, use a
`}<a parentName="li" {...{
            "href": "/components/popups/tooltip"
          }}>{`Tooltip`}</a>{` to provide additional affordance that the icon is
interactive and to improve accessibility for the Side Panel. When the Side Panel is expanded,
tooltip text reads "Collapse" and when collapsed, the tooltip reads "Expand."`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <p>{`Although the elements within a Side Panel are highly configurable to support various use cases, they
are commonly used in the following ways:`}</p>
      <h4 {...{
        "id": "local-page-navigation"
      }}>{`Local Page Navigation`}</h4>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/110fbb15edde91c94c0af265839c00a5/47218/panel-local.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "67.83783783783784%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Low-fidelity illustration of a Side Panel fixed to the left of the screen. The Panel contains an Icon Button used to collapse the Panel.",
              "title": "Low-fidelity illustration of a Side Panel fixed to the left of the screen. The Panel contains an Icon Button used to collapse the Panel.",
              "src": "/static/110fbb15edde91c94c0af265839c00a5/50383/panel-local.png",
              "srcSet": ["/static/110fbb15edde91c94c0af265839c00a5/1efb2/panel-local.png 370w", "/static/110fbb15edde91c94c0af265839c00a5/50383/panel-local.png 740w", "/static/110fbb15edde91c94c0af265839c00a5/47218/panel-local.png 1344w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ul>
        <li parentName="ul">{`Provides users with a way to navigate within an area of your product.`}</li>
        <li parentName="ul">{`Typically tied to the main content region.`}</li>
        <li parentName="ul">{`Often collapsible but not closeable, meaning the Panel remains on the page and cannot be
dismissed.`}</li>
      </ul>
      <h4 {...{
        "id": "editing-and-displaying-additional-information"
      }}>{`Editing and Displaying Additional Information`}</h4>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/01a299cd19dcab44f4c4d82cb86b8ee2/47218/panel-edit.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "67.83783783783784%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Low-fidelity illustration of a Side Panel fixed to the right of the screen. The Panel contains input fields.",
              "title": "Low-fidelity illustration of a Side Panel fixed to the right of the screen. The Panel contains input fields.",
              "src": "/static/01a299cd19dcab44f4c4d82cb86b8ee2/50383/panel-edit.png",
              "srcSet": ["/static/01a299cd19dcab44f4c4d82cb86b8ee2/1efb2/panel-edit.png 370w", "/static/01a299cd19dcab44f4c4d82cb86b8ee2/50383/panel-edit.png 740w", "/static/01a299cd19dcab44f4c4d82cb86b8ee2/47218/panel-edit.png 1344w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ul>
        <li parentName="ul">{`Ideal for editing specific content within the page or displaying additional information that
supports the main content area.`}</li>
        <li parentName="ul">{`Can be temporary, meaning the Panel may disappear when the associated content on the main page is
no longer in focus.`}</li>
      </ul>
      <h4 {...{
        "id": "panel-overlays"
      }}>{`Panel Overlays`}</h4>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d0fd94cd2e0f213bb9caad7f457b80f4/47218/panel-overlay-nav.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "67.83783783783784%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Low-fidelity illustration of a left Side Panel fixed to the right of the screen on top of an Overlay. The Panel contains a close button.",
              "title": "Low-fidelity illustration of a left Side Panel fixed to the right of the screen on top of an Overlay. The Panel contains a close button.",
              "src": "/static/d0fd94cd2e0f213bb9caad7f457b80f4/50383/panel-overlay-nav.png",
              "srcSet": ["/static/d0fd94cd2e0f213bb9caad7f457b80f4/1efb2/panel-overlay-nav.png 370w", "/static/d0fd94cd2e0f213bb9caad7f457b80f4/50383/panel-overlay-nav.png 740w", "/static/d0fd94cd2e0f213bb9caad7f457b80f4/47218/panel-overlay-nav.png 1344w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/70f7f865a3b63ae29640bb5c95658985/47218/panel-overlay-edit.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "67.83783783783784%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Low-fidelity illustration of a Side Panel fixed to the right of the screen on top of an Overlay. The Panel contains a close button and input fields.",
              "title": "Low-fidelity illustration of a Side Panel fixed to the right of the screen on top of an Overlay. The Panel contains a close button and input fields.",
              "src": "/static/70f7f865a3b63ae29640bb5c95658985/50383/panel-overlay-edit.png",
              "srcSet": ["/static/70f7f865a3b63ae29640bb5c95658985/1efb2/panel-overlay-edit.png 370w", "/static/70f7f865a3b63ae29640bb5c95658985/50383/panel-overlay-edit.png 740w", "/static/70f7f865a3b63ae29640bb5c95658985/47218/panel-overlay-edit.png 1344w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ul>
        <li parentName="ul">{`When Panels open over an overlay, the user cannot interact with the main page. The overlay helps
users focus attention on the contents of the Panel, making it ideal for higher-level navigation
and editing or displaying additional information while minimizing distractions.`}</li>
        <li parentName="ul">{`A Side Panel that opens over an overlay has a close Button but not a collapse. Activating the
Button closes the Panel and the Overlay so the user can return focus to the main page.`}</li>
      </ul>
      <h3 {...{
        "id": "dos-and-donts"
      }}>{`Do's and Don'ts`}</h3>
      <SideBySide mdxType="SideBySide">
        <Suggestion status="alert" guidance="Consider screen real estate when multiple Side Panels are present within a page. When multiple Side Panels are open at the same time, it may be overwhelming to users as their page content shrinks." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "740px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/a84cd44952ad3f57ed414622bdd97339/b1001/panel-caution.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "68.64864864864865%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABZklEQVR42qVTa2/DIAzM//+P+7BH02V5QkgIhOD5DImaaqtUFelky7IP2weF1iMZM50xzYKR/b9wmyPIMcVchbWWejXS4jzdnhACdUonDOqEdQ2SYxdH02wFbaeEtNCjIW0MDeh0spy00MpkgYtwCeDXVWI7tm07IYiNiRBtNv1Al6qmttc80kzOrzTbJY3NcM6duo8xChE6BLa4UeT4NGXCuu2p/K7pcq3ZH2QkpQ013cCXKLGeL7kn3DsOW+r6ICyrht7eL/RR/sjYKJ55L1gHRNAQI3c7Zh87tjwFVgQyXHIQVnVHn2VFX9cfIQlhk5GxbLtYIYcPIpOtdMiT7ECNAeHAKqJ4BwqRYLMv8TkhKQqbCO/P9IhQFF48C+IPtWfrJIa8GJ8kxB6htg+r+ABInVj3HKHPD/e/s6t8f8yrhGwOUV7uECPj6Sh+FUB6NjMVbddT3bQnNG1HiD9Ezun4lwG7/wtrokJZgoL7ZQAAAABJRU5ErkJggg==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image demonstrating multiple Side Panels.",
                  "title": "Image demonstrating multiple Side Panels.",
                  "src": "/static/a84cd44952ad3f57ed414622bdd97339/50383/panel-caution.png",
                  "srcSet": ["/static/a84cd44952ad3f57ed414622bdd97339/1efb2/panel-caution.png 370w", "/static/a84cd44952ad3f57ed414622bdd97339/50383/panel-caution.png 740w", "/static/a84cd44952ad3f57ed414622bdd97339/b1001/panel-caution.png 1380w"],
                  "sizes": "(max-width: 740px) 100vw, 740px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
        <Suggestion status="do" guidance="When using the Expand / Collapse Button with the Side Panel, provide a Tooltip to label the icon only Tertiary Button variant. When the Side Panel is expanded, the Tooltip contains the text &quot;Collapse&quot; and when collapsed, the Tooltip reads &quot;Expand.&quot;" mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "740px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/240d93c5e77d5aaccf35b160b70c201f/b1001/panel-tooltip-do.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "68.64864864864865%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABKUlEQVR42u2UzU6FMBCF7/u/gC6Mi/s2JiYuzTUa4x9IoaWlhdJy4ThT4Qb/YnTlwkkOJZMzX2doyqY2Dax1cK5N8r5HiBFt52HbFpZymj3OIR7yHdxK7DGNTb5NrQ06gijdgKMlgxAVhmGPECJkrZO0Mdhd38JYm3zTNKV1P45Ua1CpGszaKDI76kTTDr6PODu/wPHpFqKsUkEchrTh5e4KRydb5M9Fyo8EYiiLoZ33r0B+GBpZyBpPRUXvbQJwwRI80jLJ3NiH6ENYARuHolTICfiYC/B3HVeVbgb6PhxG/QZocZ8VuLnLkAv5ZYc/AmbU3QNBuUs+yV8D+VC4gM3c2aI10P154P/InwP7GcgXupIKZSXfiHOS7qdMd1SnlfNL7r0UiX8OL+lYPkRbl8VRAAAAAElFTkSuQmCC')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image demonstrating a Side Panel with tooltip.",
                  "title": "Image demonstrating a Side Panel with tooltip.",
                  "src": "/static/240d93c5e77d5aaccf35b160b70c201f/50383/panel-tooltip-do.png",
                  "srcSet": ["/static/240d93c5e77d5aaccf35b160b70c201f/1efb2/panel-tooltip-do.png 370w", "/static/240d93c5e77d5aaccf35b160b70c201f/50383/panel-tooltip-do.png 740w", "/static/240d93c5e77d5aaccf35b160b70c201f/b1001/panel-tooltip-do.png 1380w"],
                  "sizes": "(max-width: 740px) 100vw, 740px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
      </SideBySide>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "how-side-panels-impact-the-accessible-experience"
      }}>{`How Side Panels Impact the Accessible Experience`}</h3>
      <p>{`One of the most important aspects of Side Panel is understanding when the Side Panel’s content
begins and ends in the context of the holistic design. Side Panel uses a “landmark region” to help
establish such boundaries of the Side Panel’s content for non-visual screen reader users. Including
semantic heading text at the top of the Side Panel is recommended to help reinforce the beginning of
Side Panel content, and convey the intended purpose of the section. Finally, users must be able to
understand whether the Side Panel content is expanded or collapsed on the screen.`}</p>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Each interactive component inside Side Panel must have a focus indicator that is highly visible
against the background and against the non-focused state. Refer to
`}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for more information.`}</p>
      <p>{`Side Panel must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: focus the Side Panel toggle button, and any other interactive components inside Side Panel`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Enter`}</inlineCode>{` or `}<inlineCode parentName="li">{`Space`}</inlineCode>{`: activates Side Panel toggle button`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Side Panel must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`The Side Panel is a landmark region, named by the Side Panel’s heading text`}</li>
        <li parentName="ul">{`The “expanded” or “collapsed” state of the Side Panel`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <ul>
        <li parentName="ul">{`Specify when the Side Panel is used for navigation`}</li>
        <li parentName="ul">{`Specify heading level at the top of SIde Panel`}</li>
      </ul>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`Use semantic heading text at the top of Side Panel to describe the purpose of the content included
inside of Side Panel.`}</li>
        <li parentName="ul">{`[Included in component]`}{` Use a semantic `}<inlineCode parentName="li">{`<section>`}</inlineCode>{` element and an `}<inlineCode parentName="li">{`aria-labelledby`}</inlineCode>{` reference to
create a landmark region for screen readers.`}</li>
        <li parentName="ul">{`When Side Panel is used for navigation purposes, use the `}<inlineCode parentName="li">{`as`}</inlineCode>{` prop to change the rendered element
from the default `}<inlineCode parentName="li">{`<section>`}</inlineCode>{` to a `}<inlineCode parentName="li">{`<nav>`}</inlineCode>{` element.`}</li>
        <li parentName="ul">{`[Included in component]`}{` An accessible `}<inlineCode parentName="li">{`Tooltip`}</inlineCode>{` component is included on the Side Panel toggle
button describing what the icon button will do when activated.`}</li>
        <li parentName="ul">{`[Included in component]`}{` The toggle button must have an accessible name using either an
`}<inlineCode parentName="li">{`aria-labelledby`}</inlineCode>{` reference or an `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` string.`}</li>
        <li parentName="ul">{`[Included in component]`}{` The toggle button must convey the Side Panel state using the
`}<inlineCode parentName="li">{`aria-expanded`}</inlineCode>{` property.`}</li>
      </ul>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      